import React, { FunctionComponent } from 'react';

import CookieConsent from "react-cookie-consent";

import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

export type LayoutProps = {};

const Layout: FunctionComponent<LayoutProps> = props => {

    const location = useLocation();

    const cookieAccepted = () => {
        initializeAndTrack(location);
    };

    const cookieDeclined = () => {};

    return (
        <>
            <>{props.children}</>
            <CookieConsent
                location="bottom"
                buttonText="Ok, cool"
                enableDeclineButton
                declineButtonText="No, way"
                cookieName="gatsby-gdpr-google-analytics"
                onAccept={cookieAccepted}
                onDecline={cookieDeclined}
            >
                <span className={"cookie-banner-text"}>We use cookies</span>
            </CookieConsent>
        </>
    );
}

export default Layout;
